var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c("v-card-title", [
        _c(
          "div",
          {
            staticClass: "d-flex flex-column",
            staticStyle: { "max-width": "600px" },
          },
          [
            _c(
              "div",
              { staticClass: "d-flex align-center" },
              [
                _c(
                  "span",
                  { staticClass: "font-weight-bold secondary--text" },
                  [_vm._v("Monitoring")]
                ),
                _c(
                  "v-chip",
                  { staticClass: "ml-1", attrs: { outlined: "", small: "" } },
                  [_vm._v("Account Manager")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mt-5" },
              [
                _c("v-autocomplete", {
                  attrs: {
                    required: "",
                    items: _vm.availableResourcePools,
                    disabled: _vm.disableResourcePoolSelector,
                    loading: _vm.disableResourcePoolSelector,
                    "item-text": "long_id",
                    "item-value": "rpid",
                    dense: "",
                    label: "Resource pool",
                    outlined: "",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.changeResourcePoolData($event)
                    },
                  },
                  model: {
                    value: _vm.resourcePoolName,
                    callback: function ($$v) {
                      _vm.resourcePoolName = $$v
                    },
                    expression: "resourcePoolName",
                  },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
      _c("v-card-text", [_c("the-user-resource-admin")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }